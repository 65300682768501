<template>
  <svg viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3349 12.6402L12.1411 10.4535C12.8478 9.43583 13.2283 8.22382 13.2267 6.97928C13.2266 5.76574 12.8666 4.57948 12.1922 3.57047C11.5178 2.56133 10.5591 1.77481 9.43758 1.31036C8.31602 0.84592 7.08189 0.724401 5.89125 0.961172C4.70061 1.19794 3.60693 1.78237 2.74851 2.64057C1.89008 3.49877 1.30548 4.5922 1.06863 5.78258C0.831786 6.97297 0.953344 8.20684 1.41793 9.32815C1.88251 10.4495 2.66925 11.4079 3.67864 12.0821C4.68789 12.7563 5.87441 13.1162 7.0882 13.1163C8.33306 13.1178 9.54541 12.7374 10.5634 12.0309L12.7508 14.2242C12.8545 14.3286 12.9785 14.4121 13.1143 14.4687C13.2505 14.5254 13.3965 14.5546 13.544 14.5546C13.6914 14.5546 13.8375 14.5254 13.9736 14.4687C14.1092 14.4122 14.2324 14.3295 14.336 14.2253C14.4402 14.1217 14.5229 13.9986 14.5794 13.863C14.6362 13.7268 14.6654 13.5808 14.6654 13.4334C14.6654 13.2859 14.6362 13.1399 14.5794 13.0037C14.5228 12.8678 14.4393 12.7439 14.3349 12.6402ZM2.78519 6.97978C2.78519 6.12884 3.03759 5.29702 3.51046 4.5895C3.98334 3.88198 4.65545 3.33053 5.44182 3.00489C5.63993 2.92285 5.84305 2.85607 6.04943 2.80473C6.66221 2.65229 7.30372 2.63591 7.92817 2.76009C8.76297 2.92609 9.52979 3.33586 10.1316 3.93756C10.7335 4.53925 11.1434 5.30587 11.3094 6.14045C11.4755 6.97503 11.3903 7.84009 11.0645 8.62625C10.7388 9.41241 10.1872 10.0843 9.4795 10.5571C9.12568 10.7935 8.74078 10.9747 8.33794 11.0969C8.16829 11.1483 7.99546 11.1893 7.82044 11.2195C7.57983 11.261 7.33506 11.2822 7.0887 11.2822C5.94734 11.2822 4.85272 10.8289 4.04566 10.022C3.23859 9.21517 2.78519 8.12084 2.78519 6.97978Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconSearch'
}
</script>
